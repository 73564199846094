<template>
<div>

  <div v-for="(item, k) in configuration" :key="k">
    <div class="full-width hero">
      <div class="col-7 only-mobi" style="position: relative;">
        <img :src="item.image" style="position: absolute;top: 0;right: 50%;height: 100%;max-width: 100%;transform: translateX(50%);width:auto;" class="cover-img" />
      </div>
      <div class="col-6 center-content">
        <h1 class="align-center">{{item.title}}</h1>
        <p>{{item.content}}</p>
        <a :href="item.link" class="button" v-if="item.link.length && /^https?:\/\//.test(item.link)" target="_blank">{{item.button}}</a>
        <router-link :to="item.link" class="button" v-if="item.link.length && !/^https?:\/\//.test(item.link)">{{item.button}}</router-link>
      </div>
      <div class="col-6 no-mobi" style="position: relative;">
        <img :src="item.image" style="position: absolute;top: 0;right: 50%;height: 100%;max-width: 100%;transform: translateX(50%); width:auto;" class="cover-img" />
      </div>
    </div>
    <div class="full-width gray-spacer free-shipping" v-if="k != Object.keys(configuration).length - 1"><span></span></div>
  </div>


  <!--
  <div class="full-width hero">
    <div class="col-7 only-mobi">
      <img src="@/assets/promo.jpg" class="cover-img" />
    </div>
    <div class="col-6 center-content">
      <h1>Stanno per arrivare nuove promozioni</h1>
      <p>Cerchi prodotti di grande valore a un piccolo prezzo?</p>
      <router-link to="/category/EC66000/outlet" class="button">Scopri l'outlet</router-link>
    </div>
    <div class="col-6 no-mobi">
      <img src="@/assets/promo.jpg" class="cover-img" />
    </div>
  </div>
  -->

  <div class="full-width gray-spacer free-shipping">
    <span>Spedizione gratuita da 59&euro;</span>
  </div>
</div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Promo',
  components: {},
  metaInfo() {
    this.$utils.adabraPageView(this, { pageType: 102, pageTitle: 'Promo' });
    return {
      title: 'Promo'
    }
  },

  data: function () {
    return {
      configuration: ''
    }
  },

  async created () {
    let resp = await axios.get('https://shop.altromercato.it/cms/simplestore/vetrina.php')
    this.configuration = resp.data.banner4
    console.log(this.configuration)
  }
}
</script>

<style scoped>
.little-home {
  font-size: 18px;
  line-height: 140%;
  max-width: 80%;
}

.center-content h1 {
  text-align: center;
  max-width: 90%;
}
</style>

